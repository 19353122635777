
import { defineComponent, onMounted } from "vue";
import FinancialScoring from "@/views/financial-scoring/FinancialScoringProposal.vue";
import FinancialResolution from "@/views/financial-resolution/FinancialResolutionProposal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Table from "../../components/Table.vue";
import { useI18n } from "vue-i18n/index";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "pfp-report",
  components: {
    FinancialScoring,
    FinancialResolution,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Cash Flow", [
        "Customer",
        "Financial Statements HH",
      ]);
    });

    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const cstmr_id = route.params.cstmr_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
      isCstmrDtRdy: false,
    };

    const customerPFP = {
      id: null,
      user_id: cstmr_id,
      type: "PFP",
      raw: {
        profile: "",
        is_join_pfp: "",
        remarks: "",
      },
    };

    const basicLiquidityRatio = "0";
    const standbyLiquidityRatio = "0";
    const BasicStandbyLiquidityRatio = "0";
    const savingRatio = "0";
    const liquidAssetsToNetWorthRatio = "0";
    const debtToAssetsRatio = "0";
    const debtServiceRatio = "0";
    const nonMortgageDebtServiceRatio = "0";
    const netInvestmentAssetsToNetWorthRatio = "0";
    const solvencyRatio = "0";
    const cashflow = {};
    const cashflowReady = false;

    const networth = {};
    const networthReady = false;

    const finResolutions = [];
    const finResolutionLatest = {};

    const customerReady = false;
    const scoring = {};
    const scoringReady = false;

    const cashflowId = 0;
    const networthId = 0;
    const finResoId = 0;
    return {
      BasicStandbyLiquidityRatio,
      basicLiquidityRatio,
      cashflow,
      cashflowReady,
      cstmr_id,
      customer,
      customerReady,
      debtServiceRatio,
      debtToAssetsRatio,
      finDetails: {},
      finResolutionLatest,
      finResolutions,
      liquidAssetsToNetWorthRatio,
      netInvestmentAssetsToNetWorthRatio,
      networth,
      networthReady,
      nonMortgageDebtServiceRatio,
      router,
      savingRatio,
      scoring,
      scoringReady,
      solvencyRatio,
      standbyLiquidityRatio,
      store,
      cashflowId,
      networthId,
      finResoId,
      customerPFP,
    };
  },
  methods: {
    init() {
      console.log(this.cstmr_id);
      this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
        this.customer = this.store.getters.getCustomerData;
        for (let index of Object.keys(
          this.store.getters.getCustomerData.user_raws
        )) {
          if (
            this.store.getters.getCustomerData.user_raws[index].type == "PFP"
          ) {
            this.customerPFP = this.store.getters.getCustomerData.user_raws[
              index
            ];
          }
        }
        setTimeout(() => {
          this.customerReady = true;
          console.log(this.customer);
        }, 1000);
      });

      this.store
        .dispatch(Actions.GET_FINANCIAL_RESOLUTIONS, this.cstmr_id)
        .then(() => {
          this.finResolutions = this.store.getters.getFinancialResolutionsData.out;
          console.log(this.finResolutions);

          for (let index of Object.keys(this.finResolutions)) {
            if (!this.finResolutions[index].raw.isStepup) {
              this.finResolutionLatest = this.finResolutions[index];
              this.finResoId = this.finResolutions[index].id;
              break;
            }
          }
        });

      this.store
        .dispatch(Actions.GET_FINANCIAL_STATEMENTS, this.cstmr_id)
        .then(() => {
          let cashflow = this.store.getters.getFinancialStatementsData.cashflow;
          let networth = this.store.getters.getFinancialStatementsData.networth;
          this.scoring = this.store.getters.getFinancialStatementsData.financialScoring;

          if (cashflow && networth) {
            this.cashflowId = this.store.getters.getFinancialStatementsData.cashflow.id;
            this.networthId = this.store.getters.getFinancialStatementsData.networth.id;
          }

          if (this.scoring) {
            this.scoringReady = true;
          }

          this.cashflow = cashflow;
          this.cashflowReady = true;

          this.networth = networth;
          this.networthReady = true;

          if (cashflow && networth) {
            //Basic Liquidity Ration
            let cashEquivalance =
              networth.raw.statements.assets.child.cashCashEquivalent.total;

            let monthlyExpenses = cashflow.raw.statements.cashOutFlow.monthly;

            let basic = cashEquivalance / monthlyExpenses;

            this.basicLiquidityRatio = basic.toFixed(2);

            //Standby Liquidity Ration
            let liquidInvestmentAssets =
              networth.raw.statements.assets.child.investmentAssets.total -
              networth.raw.statements.assets.child.investmentAssets.child.epf
                .total -
              networth.raw.statements.assets.child.investmentAssets.child
                .propertyEgApartmentBungalow.total;

            // let monthlyExpenses = cashflow.raw.statements.cashOutFlow.total;
            let StandbyMonthlyExpenses = monthlyExpenses;
            let standBy = liquidInvestmentAssets / StandbyMonthlyExpenses;

            this.standbyLiquidityRatio = (
              liquidInvestmentAssets / StandbyMonthlyExpenses
            ).toFixed(2);

            this.BasicStandbyLiquidityRatio = (standBy + basic).toFixed(2);

            //savingRatio
            let savingRationNetCashFlow =
              cashflow.raw.statements.netCashFlow.annually;
            let savingRationGrossIncome =
              cashflow.raw.statements.cashInFlow.annually;

            this.savingRatio = (
              (savingRationNetCashFlow / savingRationGrossIncome) *
              100
            ).toFixed(2);

            //Liquid Assets-To Net Worth Ratio
            // let cashEquivalance =
            //   networth.raw.statements.assets.child.cashCashEquivalent.total;
            let liquidAssetsCashEquivalent = cashEquivalance;
            let liquidAssetsNetWorth = networth.raw.statements.networth.total;

            this.liquidAssetsToNetWorthRatio = (
              (liquidAssetsCashEquivalent / liquidAssetsNetWorth) *
              100
            ).toFixed(2);

            // Debt-To-Assets Ratio
            let debtTotalDebts = networth.raw.statements.lblts.total;
            let debtTotalAssets = networth.raw.statements.assets.total;

            this.debtToAssetsRatio = (
              (debtTotalDebts / debtTotalAssets) *
              100
            ).toFixed(2);

            //Debt Service Ratio

            let debtServiceTotalMonthlyLoan =
              networth.raw.statements.lblts.monthlyRepayment;
            let debtServiceTotalMonthlyTake =
              cashflow.raw.statements.cashInFlow.monthly;

            this.debtServiceRatio = (
              (debtServiceTotalMonthlyLoan / debtServiceTotalMonthlyTake) *
              100
            ).toFixed(2);

            // Non-Mortgage Debt Service Ratio
            let NonMortgageLoanRepayment =
              cashflow.raw.statements.cashOutFlow.child.carLoanRepayment
                .annually +
              cashflow.raw.statements.cashOutFlow.child.otherLoanRepayment
                .annually;
            let NonMortgageTakeHome =
              cashflow.raw.statements.cashInFlow.annually -
              cashflow.raw.statements.cashOutFlow.child.taxZakat.annually;

            this.nonMortgageDebtServiceRatio = (
              (NonMortgageLoanRepayment / NonMortgageTakeHome) *
              100
            ).toFixed(2);

            // Net Investment Assets to Net Worth Ratio
            let netInvestmentAssets =
              networth.raw.statements.assets.child.investmentAssets.total;
            let netInvestmentNetWorth = networth.raw.statements.networth.total;

            this.netInvestmentAssetsToNetWorthRatio = (
              (netInvestmentAssets / netInvestmentNetWorth) *
              100
            ).toFixed(2);

            let solvencyTotalNetWorth = networth.raw.statements.networth.total;
            let solvencyTotalAssets = networth.raw.statements.assets.total;

            this.solvencyRatio = (
              (solvencyTotalNetWorth / solvencyTotalAssets) *
              100
            ).toFixed(2);
          }
        });
    },
    onCancel() {
      this.router.push({
        path: `/customers/details/${this.cstmr_id}/dmp-plan/list`,
      });
    },
    onProceed() {
      if (
        this.cashflow &&
        this.networth &&
        this.scoring &&
        this.customerPFP.raw.profile == "PFP B" &&
        this.customerPFP.raw.is_join_pfp == "No"
      ) {
        Swal.fire({
          title: "Info!",
          text:
            "You have completed your Financial Statements. Do you want to Join PFP Program?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No, I am not!",
          confirmButtonText: "Yes, I do!",
        }).then((value) => {
          if (value.isConfirmed) {
            this.joinPFP();
          } else {
            this.proceedIncompleteProposal();
          }
        });
      } else {
        if (this.cashflowId != 0 && this.networthId != 0 && this.scoringReady) {
          this.proceedCompleteProposal();
        } else {
          this.proceedIncompleteProposal();
        }
      }
    },
    proceedCompleteProposal() {
      Swal.fire({
        title: "Warning!",
        text: "Are you sure want to proceed the PFP Proposal?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, I am not!",
        confirmButtonText: "OK",
      }).then((value) => {
        if (value.isConfirmed) {
          let data = {
            user_id: this.cstmr_id,
            fa_id: this.store.getters.currentUser.id,
            type: "PFP",
            raw: {
              cashflowId: this.cashflowId,
              networthId: this.networthId,
              finResoId: this.finResoId,
            },
            status: "PFP Report Generated",
          };

          this.store.dispatch(Actions.STORE_PFP, data).then(() => {
            window.print();
          });
        }
      });
    },

    proceedIncompleteProposal() {
      Swal.fire({
        title: "Warning!",
        text: "Are you sure want to proceed the Incomplete PFP Proposal?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, I am not!",
        confirmButtonText: "OK",
      }).then((value) => {
        if (value.isConfirmed) {
          let data = {
            user_id: this.cstmr_id,
            fa_id: this.store.getters.currentUser.id,
            type: "PFP",
            raw: {
              cashflowId: this.cashflowId,
              networthId: this.networthId,
              finResoId: this.finResoId,
            },
            status: "PFP Report Incomplete",
          };

          this.cashflow = false;
          this.networth = false;

          this.store.dispatch(Actions.STORE_PFP, data).then(() => {
            window.print();
          });
        }
      });
    },

    joinPFP() {
      let message = "Are you sure want to join PFP?";
      let title = "Join PFP!";
      if (this.customerPFP.raw.remarks) {
        title = "Rejoin PFP!";
        message = "Are you sure want to rejoin PFP?";
      }

      Swal.fire({
        title: title,
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, I am not!",
        confirmButtonText: "OK",
      }).then((value) => {
        if (value.isConfirmed) {
          this.customerPFP.raw.is_join_pfp = "Yes";
          if (this.customerPFP.raw.remarks) {
            this.customerPFP.raw.remarks = "Rejoin PFP";
          }
          this.updatePFPProfiling(this.customerPFP);
        }
      });
    },

    updatePFPProfiling(pfp) {
      this.store.dispatch(Actions.UPDATE_CUSTOMER_RAW, pfp).then(() => {
        Swal.fire({
          text: "PFP Profiling has been updated",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then((value) => {
          let data = {
            user_id: this.cstmr_id,
            fa_id: this.store.getters.currentUser.id,
            type: "PFP",
            raw: {
              cashflowId: this.cashflowId,
              networthId: this.networthId,
              finResoId: this.finResoId,
            },
            status: "PFP Report Generated",
          };
          this.store.dispatch(Actions.STORE_PFP, data).then(() => {
            window.print();
          });
        });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
