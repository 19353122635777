
import { defineComponent, onMounted } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "documents",
  components: {},
  props: ["scoring"],

  data() {
    const recommendation = "";

    return {
      recommendation,
      cashEquivalance: 0,
      monthlyExpenses: 0,
      liquidInvestmentAssets: 0,
      StandbyMonthlyExpenses: 0,
      savingRationNetCashFlow: 0,
      savingRationGrossIncome: 0,
      liquidAssetsCashEquivalent: 0,
      liquidAssetsNetWorth: 0,
      debtTotalDebts: 0,
      debtTotalAssets: 0,
      debtServiceTotalMonthlyLoan: 0,
      debtServiceTotalMonthlyTake: 0,
      NetAnnualIncome: 0,
      TaxPayable: 0,
      NonMortgageLoanRepayment: 0,
      NonMortgageTakeHome: 0,
      netInvestmentAssets: 0,
      netInvestmentNetWorth: 0,
      solvencyTotalNetWorth: 0,
      solvencyTotalAssets: 0,
    };
  },

  computed: {
    result_basic_liquidity_ratio: function () {
      let result = 0;
      result = this.cashEquivalance / this.monthlyExpenses;
      if (result) {
        return result.toFixed(2);
      } else {
        return 0;
      }
    },
    result_standby_liquidity_ratio: function () {
      let result_standby_liquidity_ratio = 0;
      result_standby_liquidity_ratio =
        this.liquidInvestmentAssets / this.monthlyExpenses;
      if (result_standby_liquidity_ratio) {
        return result_standby_liquidity_ratio.toFixed(2);
      } else {
        return 0;
      }
    },
    result_saving_ratio: function () {
      let result_saving_ratio = 0;
      let savingRationNetCashFlowPer = this.savingRationNetCashFlow;
      result_saving_ratio =
        (savingRationNetCashFlowPer / this.savingRationGrossIncome) * 100;
      if (result_saving_ratio) {
        return result_saving_ratio.toFixed(2);
      } else {
        return 0;
      }
    },
    result_liquid_assets: function () {
      let result_liquid_assets = 0;
      let liquidAssetsCashEquivalentPer = this.liquidAssetsCashEquivalent;
      result_liquid_assets =
        (liquidAssetsCashEquivalentPer / this.liquidAssetsNetWorth) * 100;
      if (result_liquid_assets) {
        return result_liquid_assets.toFixed(2);
      } else {
        return 0;
      }
    },
    // Debt-To-Assets Ratio
    result_debt: function () {
      let result_debt = 0;
      let debtTotalDebtsPer = this.debtTotalDebts * 100;
      result_debt = debtTotalDebtsPer / this.debtTotalAssets;
      if (result_debt) {
        return result_debt.toFixed(2);
      } else {
        return 0;
      }
    },
    //Debt Service Ratio
    result_debt_service_ratio: function () {
      let result_debt_service_ratio = 0;
      let debtServiceTotalMonthlyLoanPer =
        this.debtServiceTotalMonthlyLoan * 100;
      result_debt_service_ratio =
        debtServiceTotalMonthlyLoanPer / this.debtServiceTotalMonthlyTake;
      if (result_debt_service_ratio) {
        return result_debt_service_ratio.toFixed(2);
      } else {
        return 0;
      }
    },
    result_non_Mortgage: function () {
      let result_non_Mortgage = 0;
      let NonMortgageLoanRepaymentPer = this.NonMortgageLoanRepayment * 100;
      result_non_Mortgage =
        NonMortgageLoanRepaymentPer / this.NonMortgageTakeHome;
      if (result_non_Mortgage) {
        return result_non_Mortgage.toFixed(2);
      } else {
        return 0;
      }
    },
    result_net_investment_assets: function () {
      let result_net_investment_assets = 0;
      let netInvestmentAssetsPer = this.netInvestmentAssets * 100;
      result_net_investment_assets =
        netInvestmentAssetsPer / this.netInvestmentNetWorth;
      if (result_net_investment_assets) {
        return result_net_investment_assets.toFixed(2);
      } else {
        return 0;
      }
    },
    result_solvency: function () {
      let result_solvency = 0;
      let solvencyTotalNetWorthPer = this.solvencyTotalNetWorth * 100;
      result_solvency = solvencyTotalNetWorthPer / this.solvencyTotalAssets;
      if (result_solvency) {
        return result_solvency.toFixed(2);
      } else {
        return 0;
      }
    },
  },
  methods: {
    init() {
      let scoring = this.scoring.raw;
      this.cashEquivalance = scoring.cashEquivalance;
      this.monthlyExpenses = scoring.monthlyExpenses;
      this.liquidInvestmentAssets = scoring.liquidInvestmentAssets;
      this.StandbyMonthlyExpenses = scoring.StandbyMonthlyExpenses;
      this.liquidAssetsCashEquivalent = scoring.liquidAssetsCashEquivalent;
      this.debtTotalDebts = scoring.debtTotalDebts;
      this.debtTotalAssets = scoring.debtTotalAssets;
      this.debtServiceTotalMonthlyLoan = scoring.debtServiceTotalMonthlyLoan;
      this.debtServiceTotalMonthlyTake = scoring.debtServiceTotalMonthlyTake;
      this.NonMortgageLoanRepayment = scoring.NonMortgageLoanRepayment;
      this.NonMortgageTakeHome = scoring.NonMortgageTakeHome;
      this.netInvestmentAssets = scoring.netInvestmentAssets;
      this.solvencyTotalNetWorth = scoring.solvencyTotalNetWorth;
      this.solvencyTotalAssets = scoring.solvencyTotalAssets;
      this.recommendation = scoring.recommendation;
    },
    getHumanDate(date) {
      console.log(date);
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },
  },
  async mounted() {
    this.init();
  },
});
