
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "financialResolution",
  components: {},
  props: [""],
  async mounted() {
    this.init();
  },
  data() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.cstmr_id ?? null;
    const customer = {};
    const customerDataReady = false;
    const dmpProposalReady = false;

    const customerMatrix = {};

    const customerProfiling = {};

    const crdtr_infrmtn_dtls = {};

    const rstrctrdInDmp = [
      { value: "Y", label: "Yes" },
      { value: "N", label: "No" },
    ];

    const profQuestionAns = [
      { value: "1910", label: "Yes" },
      { value: "1911", label: "No" },
    ];

    const creditors = [];
    const oriCreditors = [];
    const fcltyTyp = [];
    const loanTypes = [
      {
        value: "Secured",
        label: "Secured",
      },
      {
        value: "Unsecured",
        label: "Unsecured",
      },
    ];

    const installmentMethods = [
      {
        value: 1865,
        label: "AKPK",
      },
      {
        value: 448,
        label: "Self",
      },
      {
        value: 450,
        label: "Salary Deductions",
      },
      {
        value: 449,
        label: "Angkasa",
      },
    ];

    const isSingle = [
      {
        value: "Single",
        label: "Single",
      },
      {
        value: "StepUp",
        label: "StepUp",
      },
    ];

    const ccrisUrl = "";

    const creditor = {
      loan_type: "",
      nm_of_crdtr: "",
      fclty_typ: "",
      fclty_nmbr: "",
      rstrctrd_in_dmp: "Y",
      lmt: 0,
      ori_outstndng_blnc: 0,
      outstndng_blnc: 0,
      ori_principle_amount: 0,
      disc_principle_amount: 0,
      principle_amount: 0,
      ori_iis: 0,
      disc_iis: 0,
      iis: 0,
      installment_amount: 0,
      ori_penalty_interest: 0,
      disc_penalty_interest: 100,
      penalty_interest: 0,
      ori_other_chargers: 0,
      disc_other_chargers: 0,
      other_chargers: 0,
      tenure: 120,
      interest: 0,
      legal_status_date: "",
      installment_method: 1865,
    };

    const creditorCodes = {
      "Bank Negara Malaysia": "0101",
      "Bangkok Bank Berhad": "0204",
      "Bank Of America Malaysia Berhad": "0207",
      "AmBank (M) Berhad": "0208",
      "The Bank Of Nova Scotia Berhad": "0209",
      "Bank Of Tokyo-Mitsubishi": "0210",
      "Alliance Bank Malaysia Berhad": "0212",
      "Standard Chartered Bank Malaysia Berhad": "0214",
      "J. P. Morgan Chase Bank Berhad": "0215",
      "Citibank Berhad": "0217",
      "RHB Bank Berhad": "0218",
      "Deutsche Bank": "0219",
      "HSBC Bank Malaysia Berhad": "0222",
      "Hong Leong Bank Berhad": "0224",
      "United Overseas Bank": "0226",
      "Malayan Banking Berhad": "0227",
      "OCBC Bank": "0229",
      "Affin Bank Berhad": "0232",
      "Public Bank Berhad": "0233",
      "CIMB Bank Berhad": "0235",
      "Bank of China": "0242",
      "Industrial And Commercial Bank Of China": "0259",
      "India International Bank (Malaysia) Berhad": "0260",
      "Mizuho Corporate Bank": "0261",
      "Sumitomo Mitsui Banking Corporation": "0262",
      "BNP Paribas": "0263",
      "National Bank of Abu Dhabi": "0264",
      "China Construction Bank (Malaysia) Berhad": "0265",
      "Bank Islam Malaysia Berhad": "0340",
      "Bank Muamalat Malaysia Berhad": "0341",
      "RHB ISLAMIC BERHAD": "0343",
      "CIMB Islamic Bank Berhad": "0344",
      "Hong Leong Islamic Bank Berhad": "0345",
      "Kuwait Finance House": "0346",
      "Affin Islamic Bank Berhad": "0347",
      "AmIslamic Bank Berhad": "0349",
      "Al-Rajhi Bank": "0350",
      "Public Islamic Bank Berhad": "0351",
      "MBSB Bank Berhad": "0352",
      "Alliance Islamic Bank Berhad": "0353",
      "Maybank Islamic Berhad": "0354",
      "Hsbc Amanah Malaysia Berhad": "0356",
      "Ocbc Al-Amin Bank Berhad": "0357",
      "Standard Chartered Saadiq Berhad": "0358",
      "Alliance Investment Bank Berhad": "1201",
      "AmInvestment Bank Berhad": "1202",
      "Maybank Investment Bank Berhad": "1203",
      "Public Investment Bank Berhad": "1204",
      "Hong Leong Investment Bank Bhd": "1205",
      "RHB Investment Bank Berhad": "1207",
      "MIMB Investment Bank Berhad": "1208",
      "Affin Investment Bank Berhad": "1209",
      "CIMB Investment Bank Berhad": "1210",
      "MIDF Amanah Investment Bank": "1212",
      "Kenanga Investment Bank Berhad": "1214",
      "KAF Investment Bank Berhad": "1215",
      "Affin Hwang Investment Bank Berhad": "1216",
      // "RHB Investment Bank Berhad": "1217",
      "Malaysia Building Society Berhad": "3201",
      "SME Bank Berhad": "3301",
      "Bank Pembangunan dan Infrastruktur": "3302",
      "Bank Penbangunan Sabah Berhad": "3303",
      "Malaysian Industrial Development Fin Bhd": "3304",
      "Borneo Development Corporation": "3305",
      "Bank Pertanian Malaysia Berhad": "3306",
      // "Borneo Development Corporation": "3307",
      "Export Bank Malaysia Berhad": "3308",
      "Credit Guarantee Corporation Malaysia Berhad": "3309",
      "Bank Simpanan National": "3310",
      "Bank Kerjasama Rakyat Malaysia Berhad": "3311",
      "ING Insurance Berhad": "3401",
      "Zurich Insurance Malaysia Berhad": "3403",
      "American International Assurance Bhd": "3404",
      "Danajamin Nasional Berhad": "3406",
      "Toyota Capital Malaysia Sdn Bhd": "3501",
      "TC Capital Resources Sdn Bhd": "3503",
      "BMW Credit Malaysia": "3504",
      "Naza Credit Leasing Sdn Bhd": "3505",
      "MBF Cards": "3903",
      "AEON Credit Service": "3905",
      "Diners Club Malaysia": "3907",
      "Prokhas Sdn Bhd": "4501",
      "Perbadanan Tabung Pendidikan Tinggi Nasional": "7401",
    };

    const matrixes = [
      {
        value: "Matrix 1",
        label: "Matrix 1",
      },
      {
        value: "Matrix 2",
        label: "Matrix 2",
      },
      {
        value: "Matrix 3",
        label: "Matrix 3",
      },
      {
        value: "Matrix 4",
        label: "Matrix 4",
      },
      {
        value: "Matrix 5",
        label: "Matrix 5",
      },
      {
        value: "Matrix 2 Collaboration",
        label: "Matrix 2 Collaboration",
      },
    ];

    const cities = [];
    const dmp_dstrbtns = [];

    const creditorInformation = {};

    const finReso = {};

    return {
      ccrisUrl,
      cities,
      crdtr_infrmtn_dtls,
      creditor,
      creditorCodes,
      creditorInformation,
      creditors,
      cstmr_id,
      customer,
      customerDataReady,
      customerMatrix,
      customerProfiling,
      dmpProposalReady,
      dmp_dstrbtns,
      fcltyTyp,
      finReso,
      installmentMethods,
      isLoading: true,
      loanTypes,
      matrixes,
      oriCreditors,
      page: null,
      pageCount: 1,
      profQuestionAns,
      route,
      router,
      rstrctrdInDmp,
      showAllPages: false,
      store,
    };
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  setup() {
    const { t, te, n } = useI18n();
    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const nFrmtThsnd = (data) => {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return {
      nFormat,
      nFrmtThsnd,
    };
  },

  methods: {
    init() {
      setCurrentPageBreadcrumbs("Financial Resolution", []);

      this.store
        .dispatch(Actions.GET_FINANCIAL_RESOLUTIONS, this.cstmr_id)
        .then(() => {
          let finResos = this.store.getters.getFinancialResolutionsData.out;
          let finReso = {
            raw: {
              customer: {},
              creditorInformation: {},
              crdtr_infrmtn_dtls: {},
              customerMatrix: {},
              customerProfiling: {},
              dmpDstrbtns: [],
            },
          };
          for (let index = 0; index < finResos.length; index++) {
            if (!finResos[index].raw.isStepup) {
              finReso = finResos[index];
              break;
            }
          }

          this.customer = finReso.raw.customer;
          this.creditorInformation = finReso.raw.creditorInformation;
          this.crdtr_infrmtn_dtls = finReso.raw.crdtr_infrmtn_dtls;
          this.customerMatrix = finReso.raw.customerMatrix;
          this.customerProfiling = finReso.raw.customerProfiling;
          this.dmp_dstrbtns = finReso.raw.dmpDstrbtns;
          this.customerDataReady = true;

          console.log(finReso.raw);
        });

      this.store.dispatch(Actions.GET_CITIES).then(() => {
        this.cities = this.store.getters.getCitiesData;
      });
    },
  },
});
